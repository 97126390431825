<template>
    <div>
        <!-- 1. After user has chosen a file to upload and template has been detected  -->
        <p class="text-center font-bold">VERIS template detected</p>

        <!-- 3. User has clicked "Import Template" and imported file has relevant data -->
        <template v-if="Object.keys(toUpload).length > 0">
            {{ toUpload.length }} ballot and congressional district matches were automatically added.

            <!-- 3.5 Imported file has data that needs confimring by the user to allow the import -->
            <div v-if="Object.keys(toConfirm).length > 0">
                Set the remaining options to complete the VERIS template import.
                <table class="w-full">
                    <tr>
                        <td></td>
                        <td>Ballot</td>
                        <td>Cong. district</td>
                        <td></td>
                    </tr>
                    <template v-for="entry in toConfirm">
                    <tr v-for="(ballot, ballotIdx) in entry.ballots" :key="`ballot-${entry.precinct.id}-${ballot.id}`">
                        <td>
                            {{ ballotIdx === 0 ? `${entry.precinct.key} - ${entry.precinct.name}` : '' }}
                        </td>
                        <td>{{ballot.name}}</td>
                        <td>
                            <select :ref="`congid-${entry.precinct.id}-${ballot.id}`">
                                <option v-for="congId in entry.congIds" :key="congId" :value="congId">{{congId}}</option>
                            </select>
                        </td>
                        <td>
                            <a
                                @click="confirmCongressId(entry.precinct.id, ballot.id, $refs[`congid-${entry.precinct.id}-${ballot.id}`][0].value)"
                                class="cursor-pointer no-underline"
                            >Save</a>
                            <img v-if="entry.confirmed" src="/icon-tick.svg" class="h-4 ml-2 mt-1">
                        </td>
                    </tr>
                    </template>
                </table>
            </div>


            <!-- 4. User saves data to database -->
            <div v-else class="text-center mt-5">

                <!-- This catches errors that are caused by: 
                    - a good 'import election' spreadsheet
                    - a bad 'reports and precinct data' spreadsheet 
                    i.e.
                -->

                <div v-if="errorToDisplay.length > 0">
                    <h6 class="text-red-400 mt-5">Error(s) found:</h6>
                    <p class="text-red-300 mt-5 ml-2 flex justify-center">{{ errorToDisplay[0] }}</p>
                    <div v-if="errorToDisplay.length > 1">
                        <a @click="showMoreErrors = !showMoreErrors" v-if="showMoreErrors == false" class="btn cursor-pointer ml-2 ">Show additional errors ...</a>
                        <div v-if="showMoreErrors">
                            <template v-for="error, index in errorToDisplay">
                                <p class="text-red-300 mt-2 ml-2 flex justify-center" v-bind:key="index">{{ error }}</p>
                            </template>
                        </div>
                    </div>
                    <a @click="showMoreErrors = !showMoreErrors" v-if="showMoreErrors == true" class="btn cursor-pointer ml-2 ">Hide additional errors ...</a>
                </div>
                <button v-else @click="doUpload" class="btn-success" :disabled="uploading">Save veris data</button>
            </div>
        </template>

        <template v-else>
            <!-- 2. File uploaded is either cancelled or imported -->
            <div class="text-center">
                <button @click="$emit('cancel')">Cancel</button>
                <button @click="doImport" class="btn-success ml-5">Import template</button>
            </div>

            <!-- This catches errors that are caused by: 
                - a bad 'import election' spreadsheet
                - a good 'reports and precinct data' spreadsheet
            -->
            <div v-if="errorToDisplay.length > 0">
                    <h6 class="text-red-400 mt-5">Error(s) found:</h6>
                    <p class="text-red-300 mt-5 ml-2 flex justify-center">{{ errorToDisplay[0] }}</p>
                    <p class="text-red-300 mt-5 ml-2 flex justify-center">This issue may have been caused by incorrect election data that was imported.</p>
                    <div v-if="errorToDisplay.length > 1">
                        <a @click="showMoreErrors = !showMoreErrors" v-if="showMoreErrors == false" class="btn cursor-pointer ml-2 ">Show additional errors ...</a>
                        <div v-if="showMoreErrors">
                            <template v-for="error, index in errorToDisplay">
                                <p class="text-red-300 mt-2 ml-2 flex justify-center" v-bind:key="index">{{ error }}</p>
                            </template>
                        </div>
                    </div>
                    <a @click="showMoreErrors = !showMoreErrors" v-if="showMoreErrors == true" class="btn cursor-pointer ml-2 ">Hide additional errors ...</a>
                </div>
        </template>

    </div>
</template>

<script>

import _ from 'lodash';
import { makeKey } from '@/libs/misc/';

function addToArrayUnique(ar, item) {
    if (Array.isArray(item)) {
        item.forEach(i => addToArrayUnique(ar, i));
    } else {
        if (!ar.includes(item)) {
            ar.push(item);
        }
    }
}

export default {
    props: ['spreadsheet'],
    data: function() {
        return {
            uploading: false,
            toUpload: [
                // {precinctId, ballotId, congId}
            ],
            toConfirm: {
                // precinctId: { ballots: [], precinct: {}, congIds: [] }
            },
            precinctFips: {},
            errorToDisplay: [],
            showMoreErrors: false,
        };
    },
    computed: {
        numToConfirm() {
            return Object.keys(this.toConfirm).length;
        },
    },
    methods: {
        confirmCongressId(precinctId, ballotId, congId) {
            if (!precinctId || !congId) {
                return;
            }

            this.toUpload.push({ precinctId, ballotId, congId });
            let p = this.toConfirm[precinctId];
            p.ballots = p.ballots.filter(b => b.id !== ballotId);
            if (p.ballots.length === 0) {
                this.$delete(this.toConfirm, precinctId);
            }
        },
        doImport() {
            // Do't modify the original spreadsheet
            let sheet = [...this.spreadsheet[0]];

            // Resets the error message
            this.errorToDisplay = [];

            // if the first row contains headers, remove it
            let firstRow = sheet[0];
            if (String(firstRow[0]).match(/e key/i) || String(firstRow[1]).match(/a-z/i)) {
                sheet.shift();
            }

            let findPrecinct = (precinctKey) => {
                let p = _.find(this.$results.precincts, {key: precinctKey});
                if (p) {
                    return p;
                }

                // Try without the leading 0 if it has one (0101 vs 101)
                if (precinctKey[0] === '0') {
                    p = _.find(this.$results.precincts, {key: precinctKey.substr(1)});
                }

                return p;
            };

            let precinctBallots = (precinct) => {
                let ballots = [];
                for (let bId of precinct.ballot_ids) {
                    if (this.$results.ballots[bId]) {
                        ballots.push(this.$results.ballots[bId]);
                    }
                }

                return ballots;
            };

            let added = Object.create(null);

            // Code loops through each row on the uploaded VERIS spreadsheet
            sheet.forEach((row, index) => {
                if (row.length !== 8) {
                    return;
                }

                // This pulls from the row PRECINCT CODE which contains data like e.g. 0401
                let precinctKey = row[2];

                let precinct = findPrecinct(precinctKey);
                if (!precinct) {
                    this.errorToDisplay.push(`VERIS template has a precinct that is not in the election. The 'precinct code' is ${precinctKey}, on the spreadsheet row ${index + 2}`);
                    console.log(`VERIS template has a precinct that is not in the election. Precinct = ${precinctKey}. Spreadsheet row = ${index + 2}`);
                    return;
                }

                // This pulls from the row FLIPS ID which contains data like e.g. 52362
                let fips = row[1];
                if (fips) {
                    this.precinctFips[precinct.key] = row[1];
                }

                // This creates a searchable key from each entry in the row CANDIDATE NAME e.g. exampleName
                let rowCandidateKey = makeKey(row[4]);

                
                // This creates a searchable key from each entry in the row OFFICE NAME e.g. memberboardofsupervisors
                let rowContestKey = makeKey(row[6]);


                // This seems to be creating a ballot object that has multiple contests nested inside. These ballots are from the election import data
                let ballots = precinctBallots(precinct);


                // Find the ballots that this VERIS row could be referring to, using the
                // contest+candidate name to match against our own data

                // We filter each of our own dataset of the contests in our ballots ...
                let matchingBallots = ballots.filter(b => {

                    for (let contest of Object.values(b.contests)) {

                        // We are checking this rowContestKey (e.g. memberBoardOfSupervisors from the uploaded VERIS spreadsheet)
                        // against the contests in our own dataset of ballots (aka contest.name)

                        if (makeKey(contest.name) === rowContestKey) {
                            for (let candidate of Object.values(contest.candidates)) {
                                if (makeKey(candidate.name) === rowCandidateKey) {
                                    return true;
                                }
                            }
                        }
                    }
                });

                console.log("matchningBallots :")
                console.log(matchingBallots);
                console.log(matchingBallots.length);

                if (matchingBallots.length === 0) {
                    console.log("length matched")
                    console.log(`The VERIS template has a ballot that doesn't exist in the election. The 'ballot name' is ${row[4]}, on the spreadsheet row ${index + 2}`);
                    this.errorToDisplay.push(`The VERIS template has a ballot that doesn't exist in the election. The 'ballot name' is ${row[4]}, on the spreadsheet row ${index + 2}`);
                    console.log("errorToDisplay :")
                    console.log(this.errorToDisplay)
                    return;
                }


                // If a single matching ballot then we can easily set this precinct+ballot combo to
                // the congressional ID that we have. But if we have 1+ matching ballots, then we
                // need to confirm which is which
                if (matchingBallots.length === 1) {
                    let key = `${precinct.id}-${matchingBallots[0].id}-${row[3]}`;
                    if (!added[key]) {
                        this.toUpload.push({
                            precinctId: precinct.id,
                            ballotId: matchingBallots[0].id,
                            congId: row[3],
                        });

                        added[key] = true;
                    }
                } else {
                    if (!this.toConfirm[precinct.id]) {
                        this.$set(this.toConfirm, precinct.id, {
                            precinct,
                            ballots: [],
                            congIds: [],
                        });
                    }

                    addToArrayUnique(this.toConfirm[precinct.id].ballots, matchingBallots);
                    addToArrayUnique(this.toConfirm[precinct.id].congIds, row[3]);
                }
            });

            // Go through any entries we need to confirm and auto-confirm any that only has a single
            // congressional district ID possible to choose from
            for (let check of Object.values(this.toConfirm)) {
                if (check.congIds.length === 1) {
                    let precinctId = check.precinct.id;
                    let congId = check.congIds[0];
                    for (let ballot of check.ballots) {
                        this.confirmCongressId(precinctId, ballot.id, congId);
                    }
                }
            }

            // Duplicates will occur as we check 
        },

        async doUpload() {
            // Congressional district IDs
            if (this.toUpload.length > 0) {
                let precincts = {};
                for (let entry of this.toUpload) {
                    let p = precincts[entry.precinctId] = precincts[entry.precinctId] || {
                        id: entry.precinctId,
                        ballots: {}, // will convert to array at the end
                    }

                    p.ballots[entry.ballotId] = {
                        id: entry.ballotId,
                        cong_dist: entry.congId,
                    };
                }

                for (let p of Object.values(precincts)) {
                    p.ballots = Object.values(p.ballots);
                }

                let upload = { precincts: Object.values(precincts) };

                this.uploading = true;
                try {
                    await this.$api.post('/mgr/updateprecinct', upload);
                } catch(err) {
                    this.uploading = false;
                    alert('There was an error saving veris template. Please try again soon.');
                    return;
                }

                this.uploading = false;
            }

            // FIPS
            if (Object.keys(this.precinctFips).length > 0) {
                let rows = [];
                for (let precinctKey in this.precinctFips) {
                    rows.push({
                        precinct_key: precinctKey,
                        FIPS: this.precinctFips[precinctKey],
                    });
                }

                this.uploading = true;
                try {
                    await this.$api.post('/mgr/importprecinct', {rows});
                } catch(err) {
                    this.uploading = false;
                    alert('Error updating precincts. Please try again soon');
                    return;
                }

                this.uploading = false;
            }

            this.$emit('completed', {message: 'Veris template imported'});
        },
    },

    // Check if the given spreadsheet is a veris template
    matchSpreadsheet(sheets) {
        let firstSheet = sheets[0];
        if (!firstSheet) {
            return false;
        }

        // Check on the second row incase the first has any headers
        // Row format: er,51107,107,10,candidate name, votes, ballot name, election name
        let row = firstSheet[1];
        if (
            row[0] === 'er' || row[0] === 'qr' &&
            Number.isInteger(row[1]) && // fips codes
            Number.isInteger(row[2]) && // precinct code
            String(row[2]).match(/\d{3,4}/) && // correct precinct code
            Number.isInteger(row[3]) // congressional district id
        ) {
            return true;
        }

        return false;
    },
};
</script>
