<template>
<section class="card min-w-max">
    <div class="w-6/12">
        <p>All newly exported reports and published websites will use these settings.</p>
        <div class="border border-dashed rounded border-neutral-700 bg-neutral-200 text-center">
            <form class="bg-neutral-200 p-10 text-left overflow-hidden" @submit.prevent="saveSettings">
                
                <label>
                    <span class="w-32">Election Name</span>
                    <!-- <div> -->
                        <input v-model="election.name" />
                    <!-- </div> -->
                </label>

                <label>
                    <span class="w-32">Location</span>
                    <!-- <div> -->
                        <input v-model="election.location" />
                    <!-- </div> -->
                </label>

                <label class="flex">
                    <span class="w-32" style="min-width: 8rem;">Election Date</span>
                    <div class="flex">
                        <input
                            class="w-1/4 text-center block"
                            type="number"  min="0"
                            v-model="election.date.year"
                            placeholder="yyyy"
                            style="min-width: 75px;">
                        <span class="text-2xl font-extralight">/</span>
                        <input
                            class="w-1/4 text-center block"
                            type="number"  min="0"
                            v-model="election.date.month"
                            placeholder="mm">
                        <span class="text-2xl font-extralight">/</span>
                        <input
                            class="w-1/4 text-center block"
                            type="number"  min="0"
                            v-model="election.date.day"
                            placeholder="dd">
                    </div>
                </label>
                <p class="text-right text-danger-500 italic" v-if="dateError">There is an issue with the date format</p>


                <button v-if="!saving" class="btn-success float-right">Save</button>
                <button v-else class="btn-success float-right" disabled>Saving...</button>
            </form>
        </div>
    </div>
</section>
</template>

<script>

export default {
    data: function() {
        return {
            saving: false,
            election: {
                name: '',
                location: '',
                date: {
                    year : '',
                    month : '',
                    day: ''
                }
            },
            dateError: false
        };
    },
    created() {
        this.refreshSettings();
    },
    methods: {
        refreshSettings() {
            this.election.name = this.$state.site.electionName;
            this.election.location = this.$state.site.locationName;

            if (this.$state.site.electionDate) {
                this.election.date.year = this.$state.site.electionDate.substring(0, 10).split("-")[0];
                this.election.date.month = this.$state.site.electionDate.substring(0, 10).split("-")[1];
                this.election.date.day = this.$state.site.electionDate.substring(0, 10).split("-")[2];
            }
            this.dateError = false

        },
        async saveSettings() {
            if (!this.election.name.trim() || !this.election.location.trim()) {
                return;
            }

            if (this.election.date.year || this.election.date.month || this.election.date.day) {
                if (!this.election.date.year || !this.election.date.month || !this.election.date.day) {
                    this.dateError = true;
                    return;
                }

                if (this.election.date.month > 12 || this.election.date.day > 31) {
                    this.dateError = true;
                    return;
                }
            }

            this.saving = true;
            await this.$api.post('/mgr/election', {
                name: this.election.name,
                location_name: this.election.location,
                election_date: this.election.date 
            });
            await this.$results.refresh();
            this.saving = false;

            this.refreshSettings();
        }
    },
};

</script>


<style scoped>
input{
   text-align:center;
}
</style>