<template>
<section class="card">
    <div class="w-6/12">
        <p>Import reports from spreadsheets that you have exported from other systems, such as VERIS.</p>
        <!--<p>Each spreadsheet row must include the precinct key, ie. <code>701</code>.</p>-->

        <div class="border border-dashed rounded border-neutral-700 bg-neutral-200 text-center mt-10">
            <form @submit.prevent="" class="bg-neutral-200 p-10">
                <div v-if="error" class="text-danger-500 mb-5">{{error}}</div>

                <div>
                    <input type="file" ref="fileuploader" @change="onFileChosen" />
                </div>

                <div v-if="importSuccessMessage" class="text-success-700 mt-5">{{importSuccessMessage}}</div>

                <div v-if="importComponent" class="mt-7 text-left">
                    <component
                        :is="importComponent"
                        v-bind="importComponentProps"
                        @cancel="cancelReportImport"
                        @completed="onImportCompleted"
                    />
                </div>
                <div class="mt-7 spreadsheet text-left" v-else-if="showSample && dataSample.length > 0">
                    <table v-if="!showPreview" class="w-full">
                        <tbody class="bg-white border border-neutral-500 data">
                            <tr class="table-row-info">
                                <td :colspan="Object.keys(dataSample[0]).length">
                                    <span class="step">Step 1:</span> Which column contains the precinct code?
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(col, colHeader) in dataSample[0]" :key="colHeader">
                                    <input v-if="possiblePrecinctKeyCols[colHeader]" type="radio" v-model="precinctKeyCol" :value="colHeader" />
                                </td>
                            </tr>
                            <tr v-for="(row, rowIdx) in dataSample.slice(1, 3)" :key="rowIdx">
                                <td
                                    v-for="(col, colHeader) in row"
                                    :key="colHeader"
                                    :class="[
                                        colHeader===precinctKeyCol?'precinct-col':'',
                                        importCols[colHeader].enabled?'imported-col':''
                                    ]"
                                >{{col}}</td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr><td class="h-5"></td></tr>
                        </tbody>

                        <tbody class="bg-white border border-neutral-500 data">
                            <tr class="table-row-info">
                                <td :colspan="Object.keys(dataSample[0]).length">
                                    <span class="step">Step 2:</span> Select the columns to be added to each precinct
                                    <p class="text-sm ml-14 italic">Note: Ballot and candidate details are already added</p>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(col, colIdx) in dataSample[0]" :key="colIdx">
                                    <input v-if="colIdx!==precinctKeyCol" type="checkbox" v-model="importCols[colIdx].enabled" />
                                </td>
                            </tr>
                            <tr v-for="(row, rowIdx) in dataSample.slice(1, 3)" :key="rowIdx">
                                <td
                                    v-for="(col, colHeader) in row"
                                    :key="colHeader"
                                    :class="[
                                        colHeader===precinctKeyCol?'precinct-col':'',
                                        importCols[colHeader].enabled?'imported-col':''
                                    ]"
                                >{{col}}</td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr><td class="h-5"></td></tr>
                        </tbody>

                        <tbody class="bg-white border border-neutral-500">
                            <tr class="table-row-info">
                                <td :colspan="Object.keys(dataSample[0]).length">
                                    <span class="step">Step 3:</span> <a @click="showPreview=true;" class="cursor-pointer">Preview your data import</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-else>
                        <h4>Import Preview</h4>
                        <div class="text-right mb-5">
                            <a @click="showPreview=false;" class="cursor-pointer">Back to edit data</a>
                            <button class="btn-success ml-5" :disabled="!isImportReady" @click.prevent="importData">Import Data</button>
                        </div>

                        <p>
                            Each column name will be used when exporting reports.<br/>Sampling {{dataSample.length}}/{{sheet.length}} rows.
                        </p>
                        <table class="w-full">
                            <tr>
                                <th class="pt-5 whitespace-nowrap">Precinct Code</th>
                                <th v-for="(importCol, colKey) in enabledImportCols" :key="colKey">
                                    <input type="text" v-model="importCol.name" class="w-24" placeholder="Name.." />
                                </th>
                            </tr>
                            <tr v-for="(row, rowIdx) in dataSample" :key="rowIdx">
                                <td>{{row[precinctKeyCol]}}</td>
                                <td v-for="(importCol, colKey) in enabledImportCols" :key="colKey">
                                    {{row[importCol.colIdx]}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
</template>

<style scoped>
    .spreadsheet {
        /*overflow-x: scroll;*/
    }
    .spreadsheet table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    .spreadsheet tbody.data td {
        /* TODO: Move this colour to global stylesheet to use the colour palette */
        border-left: 1px dashed #d6eaf0;
    }
    .spreadsheet tbody.data td:last-child {
        /* TODO: Move this colour to global stylesheet to use the colour palette */
        border-right: 1px dashed #d6eaf0;
    }
    .spreadsheet .table-row-info td {
        text-align: left;
        border-right: none !important;
        border-left: none !important;
    }
    .spreadsheet .table-row-info .step {
        font-weight: bold;;
    }
    .-precinct-col {
        background: red;
    }
    .-imported-col {
        background: green;
    }
</style>

<script>

import XLSX from 'xlsx';
import ImportVeris from './electiondataimports/Veris';

export default {
    components: {
        // ImportVeris,
    },
    data: function() {
        return {
            showPreview: false,
            error: '',
            showSample: false,
            dataSample: [],
            precinctKeyCol: 0,
            possiblePrecinctKeyCols: [],
            importCols: {},
            sheet: null,
            importComponent: null,
            importComponentProps: {},
            importSuccessMessage: '',
        };
    },
    computed: {
        enabledImportCols() {
            let cols = {};
            for(let prop in this.importCols) {
                if(this.importCols[prop].enabled) {
                    cols[prop] = this.importCols[prop];
                }
            }
            return cols;
        },
        isImportReady() {
            if (this.dataSample.length === 0) {
                return false;
            }

            for(let prop in this.enabledImportCols) {
                if (this.enabledImportCols[prop].enabled && !this.enabledImportCols[prop].name.trim()) {
                    return false;
                }
            }

            return true;
        },
    },
    methods: {
        reset() {
            this.showPreview = false;
            this.error = '';
            this.importSuccessMessage = '';
            this.showSample = false;
            this.dataSample = [];
            this.precinctKeyCol = 0;
            this.possiblePrecinctKeyCols = [];
            this.importCols = {};
            this.sheet = null;
        },
        resetReportImport() {
            this.reset();
            this.$refs.fileuploader.value = null;
            this.importComponent = null;
        },
        cancelReportImport() {
            this.$refs.fileuploader.value = null;
            this.importComponent = null;
        },
        async onFileChosen() {
            let file = this.$refs.fileuploader.files[0];
            if (!file) {
                this.$refs.fileuploader.value = null;
                return;
            }

            try {
                let fileBuffer = await file.arrayBuffer();
                let sheets = readSheets(fileBuffer);

                this.reset();

                if (sheets[0]) {
                    // Only interested in the first sheet
                    let sheet = sheets[0];
                    Object.freeze(sheet);
                    this.sheet = sheet;

                    // Use the first 10 rows from the first sheet as sample data
                    this.dataSample = sheet.slice(0, 10);

                    // Try to guess the precinct key column from the first row if its text headers
                    let firstRow = this.dataSample[0];
                    for (let i=0; i<firstRow.length; i++) {
                        let val = String(firstRow[i]).toLowerCase();

                        let matches = [
                            'precinct key', 'precinct code',
                            'precinct_key', 'precinct_code',
                            'precinct',
                        ];

                        if (matches.indexOf(val) > -1) {
                            this.precinctKeyCol = i;
                            break;
                        }
                    }

                    // Find all columns that have 100% possible precinct key values from row 2 onwards
                    // (row 1 could be headers that we're not interested in)
                    let cols = {};
                    sheet.forEach((row, rowNum) => {
                        if (rowNum === 0) {
                            cols = {...row};
                            for(let prop in cols) cols[prop] = true;
                        }

                        // first row could be headers so only check row 2+ for values
                        if (rowNum > 0) {
                            for(let prop in row) {
                                let val = row[prop];
                                if (!String(val).match(/^\d+$/)) {
                                    delete cols[prop];
                                }
                            }
                        }
                    });

                    // Create an index of columns that we want to import
                    let colIdxs = {};
                    firstRow.forEach((r, idx) => colIdxs[idx] = {enabled: false, name: '', colIdx: idx});
                    this.importCols = colIdxs;

                    this.possiblePrecinctKeyCols = cols;
                    if (!this.precinctKeyCol && Object.keys(this.possiblePrecinctKeyCols).length > 0) {
                        this.precinctKeyCol = parseInt(Object.keys(this.possiblePrecinctKeyCols)[0]);
                    }

                    if (ImportVeris.matchSpreadsheet(sheets)) {
                        this.importComponent = ImportVeris;
                        this.importComponentProps = {
                            spreadsheet: sheets,
                        };

                        return;
                    }
                }

                this.showSample = true;
                this.error = '';
            } catch (err) {
                this.error = err.message;
            }
        },
        onImportCompleted(event) {
            this.resetReportImport();
            if (event.message) {
                this.importSuccessMessage = event.message;
            }
        },
        async importData() {

            let upload = { rows: [] };

            // Keep a key for each row so that we don't upload duplicates
            let rowKeys = {};

            this.sheet.forEach(row => {
                let uploadRow = {};

                // importCols contains the sheet columns to import
                for (let prop in this.importCols) {
                    let col = this.importCols[prop];
                    if (!col.enabled) continue;
                    uploadRow[col.name] = row[col.colIdx];
                }

                // Manually add the precinct key so it's not overridden from importCols
                uploadRow.precinct_key = row[this.precinctKeyCol];

                // Create a key for each row so we don't have duplicate rows
                let rowKey = Object.values(uploadRow).join('\n');
                if (!rowKeys[rowKey]) {
                    upload.rows.push(uploadRow);
                    rowKeys[rowKey] = true;
                }
            });

            this.uploading = true;
            try {
                await this.$api.post('/mgr/importprecinct', upload);
                alert('Precinct data imported!');
                this.reset();
            } catch(err) {
                this.error = 'Error updating precincts';
            }

            this.uploading = false;
        },
    },
};

function readSheets(data) {
    let workbook = XLSX.read(data, {type: 'array'});

    let sheets = [];

    for (let i=0; i<workbook.SheetNames.length; i++) {
        let sheetName = workbook.SheetNames[i];
        let worksheet = workbook.Sheets[sheetName];
        let obj = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
        });
        sheets.push(obj);
    }

    return sheets;
}

</script>
