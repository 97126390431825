<template>
    <section class="card">
        <div class="w-6/12">
            <p>Change whether pollworkers are allowed to update the values for provisional voters, and for curbside voters. </p>

            <div class="border border-dashed rounded border-neutral-700 bg-neutral-200 text-center mt-5 flex justify-center">


                <section class="card bg-neutral-200 p-10 flex h-44 flex-col justify-around items-center	w-9/12 ">
                    
                    <div class="min-w-max w-96">
                        <label class="pr-5 pl-2 py-5 border border-slate-300">{{displayOptions.display_provisional_voter ? "Provisional Voters" : "Provisional Voters"}}</label>
                        <a
                            @click="()=> setDisplayOptionsForWorker(`display_provisional_voter`, false)"
                            :class="displayOptions.display_provisional_voter ? 
                            'bg-neutral-200  p-5  cursor-pointer  hover:bg-neutral-400' : 
                            'bg-neutral-600  p-5  cursor-pointer  hover:bg-neutral-600'"     
                        >Hide</a>
                        <a
                            @click="()=> setDisplayOptionsForWorker(`display_provisional_voter`, true)"
                            :class="displayOptions.display_provisional_voter ? 
                            'bg-neutral-600  p-5  cursor-pointer  hover:bg-neutral-600' : 
                            'bg-neutral-200  p-5  cursor-pointer  hover:bg-neutral-400'"
                        >Show</a>
                    </div>

                    <div class="min-w-max w-96">
                        <label class="pr-5 pl-5 py-5 border border-slate-300">{{displayOptions.display_curbside_voter ? "Curbside Voters" : "Curbside Voters"}}</label>

                        <a
                            @click="()=> setDisplayOptionsForWorker(`display_curbside_voter`, false)"
                            :class="displayOptions.display_curbside_voter ? 
                            'bg-neutral-200  p-5  cursor-pointer  hover:bg-neutral-400' : 
                            'bg-neutral-600  p-5  cursor-pointer  hover:bg-neutral-600'"
                        >Hide</a>
                        <a
                            @click="()=> setDisplayOptionsForWorker(`display_curbside_voter`, true)"
                            :class="displayOptions.display_curbside_voter ? 
                            'bg-neutral-600  p-5  cursor-pointer  hover:bg-neutral-600' : 
                            'bg-neutral-200  p-5  cursor-pointer  hover:bg-neutral-400'"
                        >Show</a>
                    </div>

                </section>
            </div>
        </div>
        <div class="w-6/12 pt-10" v-if="displayOptions.provisional_parties.parties">
            <p>Change whether when adding or editing provisional voters, pollworkers are required to specify the party to which the voter belongs.</p>

            <div class="border border-dashed rounded border-neutral-700 bg-neutral-200 text-center mt-5 flex justify-center">


                <section class="card bg-neutral-200 p-10 flex h-44 flex-col justify-around items-center	w-9/12 ">
                    
                    <div class="min-w-max w-96">
                        <label class="pr-5 pl-5 py-5 border border-slate-300">{{displayOptions.provisional_parties.parties_enabled ? "Require Party Infomation" : "Require Party Infomation"}}</label>

                        <a
                            @click="()=> setDisplayOptionsForWorker(`provisional_parties`, false)"
                            :class="displayOptions.provisional_parties.parties_enabled ? 
                            'bg-neutral-200  p-5  cursor-pointer  hover:bg-neutral-400' : 
                            'bg-neutral-600  p-5  cursor-pointer  hover:bg-neutral-600'"
                        >False</a>
                        <a
                            @click="()=> setDisplayOptionsForWorker(`provisional_parties`, true)"
                            :class="displayOptions.provisional_parties.parties_enabled ? 
                            'bg-neutral-600  p-5  cursor-pointer  hover:bg-neutral-600' : 
                            'bg-neutral-200  p-5  cursor-pointer  hover:bg-neutral-400'"
                        >True</a>
                    </div>

                </section>
            </div>
        </div>

        <div class="w-6/12 pt-10">
            <p>Change the percentage at which a user will be warned of errors when trying to submit votes if the 'Voters Checked In' and 'Number of Votes Cast on Machine(s)' are different.</p>

            <div class="border border-dashed rounded border-neutral-700 bg-neutral-200 text-center flex flex-col items-center mt-5 justify-center">


                <section class="card bg-neutral-200 pt-10 pb-2 flex flex-col justify-start items-center w-9/12" style="margin-bottom: 8px;!important ">
                    <div class="min-w-max w-96 flex justify-center py-5 ">
                        <label class="pr-5 pl-2">Votes Discrepancy (%)</label>
                        <input type="range" min="0" max="100" v-model="displayOptions.votes_discrepancy" class="slider">
                        <div class="input-group pl-2">
                            <input type="number" min="0" max="100" v-model="displayOptions.votes_discrepancy" class="number-input w-12">
                        </div>
                    </div>
                    <p class="italic" v-if="displayOptions.votes_discrepancy == 0">When set to 0%, any difference in 'Voters Checked In' and 'Number of Votes Cast on Machine(s)' will be flagged.</p>
                    <p class="italic text-red-500" v-if="displayOptions.votes_discrepancy == 100">When set to 100%, the 'Voters Checked In' will not be checked against the 'Number of Votes Cast on Machine(s)'!</p>
                </section>
                <div class="flex justify-end mb-2 w-9/12">
                    <button v-if="displayOptions.votes_discrepancy !== displayOptions.initial_votes_discrepancy" @click="displayOptions.votes_discrepancy = displayOptions.initial_votes_discrepancy" class="btn-danger h-10 rounded">Reset</button>
                    <button v-if="displayOptions.votes_discrepancy !== displayOptions.initial_votes_discrepancy" @click="()=> setDisplayOptionsForWorker(`votes_discrepancy`, displayOptions.votes_discrepancy)" class="btn-success h-10 rounded bg-white">Submit</button>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

input{
   text-align:center;
}
.input-group {
    display: flex;
    align-items: center;
    width: fit-content;
}

.number-input {
    border-right: none;
}

.input-group-text {
    background-color: transparent;
    border-left: none;
}
</style>

<script>

export default {
    data: function() {
        return {
            uploading: false,
            error: '',
            nonCriticalError :'',
            success: false,
            displayOptions: {
                display_curbside_voter : true,
                display_provisional_voter : true, 
                votes_discrepancy : 0, 
                initial_votes_discrepancy: null,
                provisional_parties: {
                    parties_enabled: false,
                    parties : {}
                }
            }
        };
    },
    async mounted() {

        await this.$api.post('/mgr/displayoptions',{options:null})
            .then(resp => {
                this.displayOptions.display_curbside_voter = resp.returnedOptions.curbside;
                this.displayOptions.display_provisional_voter = resp.returnedOptions.provisional;
                this.displayOptions.votes_discrepancy = resp.returnedOptions.votes_discrepancy;
                this.displayOptions.initial_votes_discrepancy = resp.returnedOptions.votes_discrepancy;
                this.displayOptions.provisional_parties.parties_enabled = resp.returnedOptions.provisional_parties.parties_enabled;
                this.displayOptions.provisional_parties.parties = resp.returnedOptions.provisional_parties.parties;
            })
            .catch(err => {
                console.error(err);
            });
    },
    methods: {
        async onFileChosen() {
            this.uploading = true;
            this.success = false;

            let file = this.$refs.fileuploader.files[0];
            let form = new FormData();
            form.append('data', file);

            try {
                let resp = await this.$api.post('/mgr/importelection', form);

                if (resp.nonCriticalError) {
                    this.nonCriticalError = resp.nonCriticalError;
                }
                if (resp && resp.error) {
                    throw new Error(resp.error.message);
                }
                this.success = true;
                this.error = '';
            } catch (err) {
                this.error = err.message;
            }
            
            this.uploading = false;
        },
        async clearElection() {
            if (!confirm('Clear all election data? This is not reversable!')) {
                return;
            }

            let resp = await this.$api.post('/mgr/importelection/clear', {
                csrf: '',
            });

            if (resp.error) {
                alert(resp.error.message);
                return;
            }

            window.location.reload();
        },
        async setDisplayOptionsForWorker(option, passedValue){

            let response = await this.$api.post('/mgr/displayoptions', {changedOption : option , value : passedValue})

            if (response.status === 200) {
                if (response.returnedOption === 'votes_discrepancy') {
                    this.displayOptions.initial_votes_discrepancy = response.value;
                    this.displayOptions.votes_discrepancy = response.value;
                } else if (response.returnedOption === 'provisional_parties') {
                    this.displayOptions.provisional_parties.parties_enabled = response.value;
                } else {
                    this.displayOptions[response.returnedOption] = response.value;
                }
            }

 
        },
    },
};

</script>
