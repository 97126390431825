<template>
    <div class="main">


        <div class="card-title">
            <h3>Election settings</h3>
            <div>Change your election settings, import or change existing election data</div>
        </div>
        <settings></settings>

        <div class="card-title">
            <h3>Import election</h3>
            <div>Setup your election and polling locations</div>
        </div>
        <import-election></import-election>

        <div class="card-title">
            <h3>Change pollworker options</h3>
            <div>Edit what actions pollworkers are able to take</div>
        </div>
        <ElectionDataDisplayOptions></ElectionDataDisplayOptions>

        <div class="card-title">
            <h3 id="VERIS">Import reports and precinct data</h3>
            <div>Add more data to your precincts to enable extra reporting</div>
        </div>
        <precinct-data></precinct-data>

        <div class="card-title">
            <h3>Clear all election data</h3>
            <div>Add more data to your precincts to enable extra reporting</div>
        </div>
        <clear-election></clear-election>
    </div>
</template>

<script>


import ImportElection from './ElectionDataImportElection';
import PrecinctData from './ElectionDataPrecinctData';
import ElectionDataDisplayOptions from "./ElectionDataDisplayOptions";
import ClearElection from './ElectionDataClearElection';
import Settings from './ElectionDataSettings';


export default {
    components: {
        ImportElection,
        PrecinctData,
        ClearElection,
        Settings,
        ElectionDataDisplayOptions
    },
    mounted() {
        const params = new URLSearchParams(window.location.search);
        if (params.get('scrollTo') === 'VERIS') {
            const element = this.$el.querySelector("#VERIS");
          if (element) {
            element.scrollIntoView();
          }
        }
    }
}

</script>
