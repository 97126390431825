<template>
<section class="card">
        <div class="w-6/12">
            <div class="border-danger-700 bg-danger-300 border p-5 text-center">
                <p>Delete all locations, results, messages, and any other data related to this election. All users other than yourself will be deleted.</p>
                <form>
                    <label class="block">
                        <span class="w-auto">I understand that everything will be deleted</span>
                        <input type="checkbox" v-model="iUnderstand" />
                    </label>
                    <button class="btn-danger mt-5" v-if="iUnderstand" @click.prevent="clearElection">Clear election data</button>
                </form>
            </div>
        </div>
</section>
</template>

<script>

export default {
    data: function() {
        return {
            iUnderstand: false,
        };
    },
    methods: {
        async clearElection() {
            if (!confirm('Clear all election data? This is not reversable!')) {
                return;
            }

            let resp = await this.$api.post('/mgr/importelection/clear', {
                csrf: '',
            });

            if (resp.error) {
                alert(resp.error.message);
                return;
            }

            window.location.reload();
        },
    },
};

</script>
