<template>
    <section class="card">
        <div class="w-6/12">
            <p>You can import your election data including polling locations and ballots.</p>
            <p>Data in your import will be added to your election, but no existing data will be lost or overwritten.</p>
            <p>Download the example <a href="/resources/tallypoint_election_import.xlsx">Excel spreadsheet</a> to use as a template, then upload your completed spreadsheet below.</p>

            <div class="border border-dashed rounded border-neutral-700 bg-neutral-200 text-center mt-10">
                <form class="bg-neutral-200 p-10">
                    <div v-if="error" class="text-danger-500 mb-5">{{error}}</div>
                    <div v-if="success" class="text-success-600">
                        Election data import completed!
                        <div v-if="nonCriticalError" class="text-danger-500 mb-5 mt-5">
                            <p>{{nonCriticalError}}. The election has still been imported but some functionality will not be present.</p>
                        </div>
                        <router-link class="mt-5 block" :to="routeurl('manager.precinct_status')">Show precinct status</router-link>
                        <router-link class="mt-5 block" :to="routeurl('manager.reorder_contests')">Change Contest Order </router-link>
                    </div>

                    <div v-else>
                        <input type="file" ref="fileuploader" @change="onFileChosen" />
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    data: function() {
        return {
            uploading: false,
            error: '',
            nonCriticalError :'',
            success: false,
        };
    },
    mounted() {
    },
    methods: {
        async onFileChosen() {
            this.uploading = true;
            this.success = false;

            let file = this.$refs.fileuploader.files[0];
            let form = new FormData();
            form.append('data', file);

            try {
                let resp = await this.$api.post('/mgr/importelection', form);
                console.log('upload response', resp);

                if (resp.nonCriticalError) {
                    this.nonCriticalError = resp.nonCriticalError;
                }
                if (resp && resp.error) {
                    throw new Error(resp.error.message);
                }
                this.success = true;
                this.error = '';
            } catch (err) {
                this.error = err.message;
            }
            
            this.uploading = false;
        },
        async clearElection() {
            if (!confirm('Clear all election data? This is not reversable!')) {
                return;
            }

            let resp = await this.$api.post('/mgr/importelection/clear', {
                csrf: '',
            });

            if (resp.error) {
                alert(resp.error.message);
                return;
            }

            window.location.reload();
        },
    },
};

</script>
